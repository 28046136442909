<script>
import { ArrowUpIcon, MapPinIcon, PlusIcon, MoreHorizontalIcon } from "vue-feather-icons";


import SearchLine from "./SearchLine";
import PostHighlightLess from "./PostHighlightLess";

import store from "@/store/store";
/**
 * Index-hosting component
 */
export default {

  components: {
    SearchLine,
    PostHighlightLess,

    ArrowUpIcon,
    MapPinIcon,
    PlusIcon,
    MoreHorizontalIcon,

  },
  data() {
    return {
      hover: false,

      additionalParamentersMenu: false,

      search_line_string: '',
      andOrFilter_Options: [
        { id: 1, value: 'Все слова' },
        { id: 2, value: 'Любое из слов' },
      ],
      andOrFilter_Selected: { id: 1, value: 'Все слова' },

      whereToSearchFilter_Options: [
        { id: 1, value: 'Fulltext search' },
        { id: 2, value: 'В текстах' },
        { id: 3, value: 'В авторах' },
        { id: 4, value: 'Город' },
      ],
      whereToSearch_Selected: { id: 1, value: 'Fulltext search' },


      additionalSearchLines: [1, 2],
      advancedSearchSwitch: true,





      input_advanced_search: {
        user_id: 1,
        searchParameters: [],
        searchFlags: {},
        page: 1,
        resumes_per_page: 10,
        hash: '',
      },

      searchLine: '',

      search_parameters: [
        {
          leftParethesis: false,
          field: { name: 'Resume headline', id: 1, type: 'String' },
          relationship: { name: 'contains', id: 2 },
          value: '',
          orButton: false,
          andButton: false,
          rightParethesis: false,
          id: 0
        }
      ],

      search_parameters_arr: [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }],

      search_parameters_obj: {
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      },
      search_parameters_additional: [],

      search_flags: {
        switchEmail: false,
        switchContact: false,

        switchRemote: false,
        switchReadyToMove: false,

        switchHighlighView: false,
        switchResumeView: false,
        switchSaved: false,
      },

      setSQL_ids: {
        ids_arr: [1, 2],
        save_search_id: 1,
        user_id: 2,
      },

      savedSearch: { name: 'Базовый', id: 0, type_id: 0 },

      resumeIds: [],
      resumes: [],

      total_resume: 5836,

      allVacancies: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      activeVacancies: [],
      archiveVacancies: [],

      latestSearches: [],


      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id: 0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
      },

      totalSearchResults: 1,
      page: {
        active: 1,
        up: 2,
        down: 1,
        max: 3,
        min: 1,
        left_skip: false,
        right_skip: false,
        prev: true,
        next: true,
        skip_number: 3,
      },
      page_arr: [],

      filters: {
        industryFilter: false,
        gradeFilter: false,
        lastActiveFilter: false,
        jobTypeFilter: false,
        experianceFilter: false,
      },


    };
  },
  watch: {
    whereToSearch_Selected(val) {
      this.search_parameters_arr[0].whereToSearchFilter_id = val.id;
    },
    andOrFilter_Selected(val) {
      this.search_parameters_arr[0].andOrFilter_id = val.id;
    },
    search_line_string(val) {

      this.search_parameters_arr[0].search_line_string = val;

    }
  },
  mounted() {


    this.loadJobs();
  },

  methods: {



    nullSearch() {
      this.search_line_string = '';
      this.andOrFilter_Selected = { id: 1, value: 'Все слова' };
      this.whereToSearch_Selected = { id: 1, value: 'Везде' };
      this.search_parameters_arr = [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }];
      this.search_parameters_additional = [];
    },


    addRemoveLine(x) {
      if (x === -1) {
        this.search_parameters_additional.push(this.search_parameters_obj);
        this.search_parameters_arr.push(this.search_parameters_obj);
      } else {
        this.search_parameters_additional.splice(x, 1);
        this.search_parameters_arr.splice(x + 1, 1);
      }
      console.log('inside addRemoveLine:')
      console.log(this.search_parameters_arr);
    },

    updateParameters(e) {
      console.log('inside updateParameters')
      console.log(e);
      this.addRemoveLine(e);
    },

    update_search_parameters_arr(e) {
      console.log('inside update_search_parameters_arr')
      //console.log(e);

      this.search_parameters_arr[e.index + 1].search_line_string = e.search_line_string;
      this.search_parameters_arr[e.index + 1].andOrFilter_id = e.andOrFilter_id;
      this.search_parameters_arr[e.index + 1].whereToSearchFilter_id = e.whereToSearchFilter_id;
      //this.search_parameters_additional = e.splice(0, 1);
      console.log(this.search_parameters_arr);
    },





    loadJobs() {

      console.log('inside loadResumes')
      /*
      this.inputSqlContacts.searchButtonIsPressed = 1;
      this.inputSqlContacts.step = 1
      */
      this.postIds = [];
      this.posts = [];



      this.input_advanced_search.user_id = 9999;
      //this.input_advanced_search.searchParameters = this.search_parameters_arr;
      //this.input_advanced_search.searchFlags = this.search_flags;
      this.input_advanced_search.hash = this.$route.params.search_hash;
      this.input_advanced_search.posts_per_page = 10;
      this.input_advanced_search.page = parseInt(this.$route.params.page);


      /*
      const loading = this.$vs.loading({
        target: this.$refs.resumesListArea,
        scale: 1,
        opacity: 0.1,
      });
      */

      this.$vs.loading({
        container: '#div-with-loading',
        scale: 1,
        opacity: 0.1,
      });

      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/search-ba/get-posts-per-page',
        data: this.input_advanced_search,
      }).then((res) => {

        if (res.data.status === 'ok') {


          this.totalSearchResults = res.data.total_posts;


          this.page.max = Math.ceil(res.data.total_posts / 10);

          this.paginationUtil(parseInt(this.$route.params.page));


          this.setSQL_ids.ids_arr = res.data.resume_ids;

          this.setSQL_ids.save_search_id = this.savedSearch.id;

          console.log(this.setSQL_ids)
          console.log(this.page);
          console.log(this.page_arr);
          var p = JSON.parse(res.data.search_parameters);
          var p1 = p.searchParameters[0]
          this.search_line_string = p1.search_line_string;

          this.whereToSearch_Selected = this.whereToSearchFilter_Options.filter(x => x.id === p1.whereToSearchFilter_id)[0]
          this.andOrFilter_Selected = this.andOrFilter_Options.filter(x => x.id === p1.andOrFilter_id)[0]
          this.search_parameters = p.searchParameters;
          this.search_parameters_arr = p.searchParameters;

          if (p.searchParameters.length > 1) {
            //this.search_parameters_additional = p.searchParameters.splice(0,1);
            for (let i = 1; i < p.searchParameters.length; i++) {
              this.search_parameters_additional.push(p.searchParameters[i]);
            }

          }

          console.log('search_parameters_arr');
          console.log(this.search_parameters_arr);
          console.log('search_parameters_additional');
          console.log(this.search_parameters_additional);

          this.search_flags = p.searchFlags;


          if (this.setSQL_ids.ids_arr.length > 0) {
            this.axios({
              method: 'post',

              url: '' + this.$hostnamejava + '/search-ba/get-post-byIdList-detail',
              data: this.setSQL_ids,
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }).then((res_in) => {
              console.log('inside 2nd axios - get resumes')

              if (res_in.data.length) {
                for (var k = 0; k < res_in.data.length; k++) {
                  this.posts.push(res_in.data[k]);
                }

              }
              this.$vs.loading.close("#div-with-loading > .con-vs-loading");
              //loading.close();
            }).catch((error) => {
              this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            });


          }
        }
      });
      //this.saveLatestSearchParameters();


    },

    searchPosts() {

      this.postIds = [];
      this.posts = [];




      this.input_advanced_search.searchParameters = this.search_parameters_arr;
      this.input_advanced_search.searchFlags = this.search_flags;


      console.log('inside search jobs')

      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.5,
        opacity: 0.1,
      });
      this.axios({
        method: 'post',

        url: '' + this.$hostname + '/search-ba/get-hash',
        data: this.input_advanced_search,
      }).then((res) => {
        //        console.log(res.data);
        if (res.data.status === 'ok') {

          let route = this.$router.resolve({ path: '/search-ba/' + res.data.hash + '/page/1' });
          window.open(route.href, '_self');
        }
      }).catch((err) => {
        console.error(err);
        loading.close();

      });




    },



    paginationUtil(x) {
      this.page.active = parseInt(x);
      this.page.up = Math.min(this.page.active + 1, this.page.max);
      this.page.down = Math.max(this.page.active - 1, this.page.min);
      if (this.page.active > this.page.min) {
        this.page_arr.push({ page: '' + this.page.min, active: 'notactive' });
      }
      if (this.page.active === this.page.min) {
        this.page.prev = false;
      }
      if (this.page.active === this.page.max) {
        this.page.next = false;
      }

      if (this.page.active - this.page.min >= this.page.skip_number) {
        this.page.left_skip = true;
        this.page_arr.push({ page: '...', active: 'notactive' });
        this.page_arr.push({ page: '' + this.page.down, active: 'notactive' });
        if (this.page.active < this.page.max) {
          this.page_arr.push({ page: '' + this.page.active, active: 'active' });
        }
      } else {

        for (var i = 2; i < this.page.active; i++) {
          this.page_arr.push({ page: '' + i, active: 'notactive' });
        }
        if (this.page.active < this.page.max) {
          this.page_arr.push({ page: '' + this.page.active, active: 'active' });
        }

      }
      if (this.page.max - this.page.active >= this.page.skip_number) {
        this.page.right_skip = true;
        this.page_arr.push({ page: '' + this.page.up, active: 'notactive' });
        this.page_arr.push({ page: '...', active: 'notactive' });
        this.page_arr.push({ page: '' + this.page.max, active: 'notactive' });

      } else {
        for (var i = this.page.up; i <= this.page.max; i++) {

          if (this.page.max === i & this.page.active === this.page.max) {
            this.page_arr.push({ page: '' + i, active: 'active' });
          } else {
            this.page_arr.push({ page: '' + i, active: 'notactive' });
          }
        }

      }
      console.log(this.page_arr);
      console.log(this.page);
    },



    PaginationStep(x) {

      if (x === -1) {
        let route = this.$router.resolve({ path: '/search-ba/' + this.$route.params.search_hash + '/page/' + this.page.down });
        window.open(route.href, '_self');
      } else if (x === -2) {
        let route = this.$router.resolve({ path: '/search-ba/' + this.$route.params.search_hash + '/page/' + this.page.up });
        window.open(route.href, '_self');
      } else {
        let route = this.$router.resolve({ path: '/search-ba/' + this.$route.params.search_hash + '/page/' + x });
        window.open(route.href, '_self');
      }

    },




  },


};
</script>

<template>
  <div>
    <!-- Search Start -->
    <section class="section-two bg-light">
      <div class="container mt-5 vs-con-loading__container" id="div-with-loading">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <form class="p-4 shadow bg-white rounded">
              <div class="d-flex justify-content-between">
                <h4 class="mb-3">Jobs search</h4>

              </div>
              <div class="row">
                <div class="col-10 ">


                  <div class="input-group-append form-group">





                    <input name="name" id="name" type="text" class="form-control rounded-left col-6"
                      placeholder="Enter keywords :" v-model="search_line_string" />

                    <select class="form-control custom-select rounded-0" v-model="whereToSearch_Selected">
                      <option v-bind:value="x" v-for="x in whereToSearchFilter_Options">{{ x.value }}</option>
                    </select>
                    <select class="form-control custom-select rounded-0" v-model="andOrFilter_Selected">
                      <option v-bind:value="x" v-for="x in andOrFilter_Options">{{ x.value }}</option>
                    </select>



                    <input class="searchbtn btn btn-primary btn-block " value="Поиск" @click="searchPosts" />

                  </div>
                  <SearchLine v-for="(line, ix) in search_parameters_additional" :index="ix" @index="updateParameters"
                    :search_parameters_arr="search_parameters_arr"
                    @search_parameters_arr="update_search_parameters_arr" />
                </div>
                <div class="col-2">
                  <a class="btn btn-icon btn-md btn-outline-info btn-pills mr-1" @click="addRemoveLine(-1)"><plus-icon
                      class="fea icon-md"></plus-icon></a>

                </div>

              </div>


            </form>

          </div>

        </div>

      </div>

    </section>




    <!-- Job List Start -->
    <!--<section class="section">-->
    <div>
      <div class="row m-4">
        <div class="col-lg-3 col-md-6 col-12">

        </div>
        <div class="col-lg-8 col-md-6 col-12">
          <h3> {{ new Intl.NumberFormat().format(totalSearchResults).replace(',', ' ') }} jobs found </h3>
        </div>
      </div>
      <div class="row ml-4 mr-4">
        <div class="col-lg-3 col-md-6 col-12">

        </div>
        <!--end col-->

        <div class="col-lg-9 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">

          <div class="row">

            <PostHighlightLess :post="post" :index="ix" v-for="(post, ix) in posts" />

          </div>

          <div class="widget">
            <ul class="pagination justify-content-center mb-0">
              <li class="page-item" @click="PaginationStep(-1)">
                <a class="page-link" @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }"
                  v-if="page.prev" aria-label="Previous">Пред</a>
              </li>

              <li class="page-item" :class="x.active" v-for="x in page_arr" @click="PaginationStep(x.page)">
                <a class="page-link" @mouseover="hover = true" @mouseleave="hover = false"
                  :class="{ active: hover }">{{ x.page }}</a>
              </li>

              <li class="page-item" @click="PaginationStep(-2)">
                <a class="page-link" @mouseover="hover = true" @mouseleave="hover = false" :class="{ active: hover }"
                  v-if="page.next" aria-label="Next">След</a>
              </li>
            </ul>
          </div>


        </div>

      </div>

      <!-- </section> -->
    </div>





    <!--
        <Footer />

        <Switcher />
        -->

    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>


<style lang="scss">
.active {
  cursor: pointer;
}
</style>