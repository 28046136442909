<template>
  <div class="card w-100 mt-2">
    <div class="public-profile border-0 rounded shadow">

      <div class="row align-items-end">
        <div class="text-lg-left text-center ml-4">


          <h4 class="title mb-0" @click="toPost(post.id)" @mouseover="hover = true" @mouseleave="hover = false"
            :class="{ active: hover }">
            {{ post.job_title }}
          </h4>

          <ul class="list-inline mb-0 mt-2">
            <li class="list-inline-item mr-2"><small class="text-muted h6 mr-2">{{ post.company_name }} -</small></li>
            <li class="list-inline-item">
              <map-pin-icon class="fea icon-sm"></map-pin-icon> <small class="text-muted h6 mr-2">
                {{ post.location }}</small>
            </li>

          </ul>
          <div class="row align-items-end">
            <span v-html="post.description"></span>
          </div>
        </div>

      </div>



    </div>

  </div>
</template>

<script>

import {
  MapPinIcon,
} from "vue-feather-icons";
import store from "@/store/store";

export default {
  name: "PostHightlight",
  components: {
    MapPinIcon
  },
  props: {
    post: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },


  },
  data() {
    return {
      hover: false,
      saveResume: {
        user_id: 1,
        resume_id: 1,
        save_search_name: '',
        save_search_id: 1,
      },
      move_resumes_sql: {
        user_id: 1,
        vacancy_id: 1,
        resume_ids: [],
        funnel_stage_from: 1,
        funnel_stage_to: 1,
        neighbor: true,
      },

    }
  },

  methods: {

    toPost(x) {
      let route = this.$router.resolve({ path: '/post-detail/' + x });
      window.open(route.href, '_blank');
    },



  }
}
</script>


<style lang="scss">
.active {
  cursor: pointer;
}
</style>